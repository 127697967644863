/* src/index.css */
*,
::before,
::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}
*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}
::before,
::after {
  --tw-content: "";
}
html,
:host {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family:
    ui-sans-serif,
    system-ui,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
}
body {
  margin: 0;
  line-height: inherit;
}
hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}
abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}
a {
  color: inherit;
  text-decoration: inherit;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp,
pre {
  font-family:
    ui-monospace,
    SFMono-Regular,
    Menlo,
    Monaco,
    Consolas,
    "Liberation Mono",
    "Courier New",
    monospace;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}
button,
select {
  text-transform: none;
}
button,
input:where([type=button]),
input:where([type=reset]),
input:where([type=submit]) {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}
:-moz-focusring {
  outline: auto;
}
:-moz-ui-invalid {
  box-shadow: none;
}
progress {
  vertical-align: baseline;
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
summary {
  display: list-item;
}
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}
fieldset {
  margin: 0;
  padding: 0;
}
legend {
  padding: 0;
}
ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
dialog {
  padding: 0;
}
textarea {
  resize: vertical;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}
button,
[role=button] {
  cursor: pointer;
}
:disabled {
  cursor: default;
}
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}
img,
video {
  max-width: 100%;
  height: auto;
}
[hidden]:where(:not([hidden=until-found])) {
  display: none;
}
:root {
  --radius: 0.5rem;
  --aside-width: 260px;
  --header-height: 55px;
  --background: 98% 0 0;
  --foreground: 2% 0 0;
  --card: 98% 0 0;
  --card-foreground: 2% 0 0;
  --popover: 98% 0 0;
  --popover-foreground: 2% 0 0;
  --primary: 2% 0 0;
  --primary-foreground: 98% 0 0;
  --secondary: 90% 0 0;
  --secondary-foreground: 10% 0 0;
  --muted: 94% 0 0;
  --muted-foreground: 45% 0 0;
  --accent: 94% 0 0;
  --accent-foreground: 10% 0 0;
  --destructive: 50% 75 30;
  --destructive-foreground: 94% 0 0;
  --border: 86% 0 0;
  --input: 86% 0 0;
  --ring: 2% 0 0;
  --sidebar-background: 98% 0 0;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 98% 0 0;
  --sidebar-accent: 240 4.8% 95.9%;
  --sidebar-accent-foreground: 240 5.9% 10%;
  --sidebar-border: 220 13% 91%;
  --sidebar-ring: 2% 0 0;
  --chart-1: 12 76% 61%;
  --chart-2: 173 58% 39%;
  --chart-3: 197 37% 24%;
  --chart-4: 43 74% 66%;
  --chart-5: 27 87% 67%;
}
.dark {
  --background: 2% 0 0;
  --foreground: 98% 0 0;
  --card: 2% 0 0;
  --card-foreground: 98% 0 0;
  --popover: 2% 0 0;
  --popover-foreground: 98% 0 0;
  --primary: 98% 0 0;
  --primary-foreground: 2% 0 0;
  --secondary: 10% 0 0;
  --secondary-foreground: 90% 0 0;
  --muted: 6% 0 0;
  --muted-foreground: 55% 0 0;
  --accent: 6% 0 0;
  --accent-foreground: 90% 0 0;
  --destructive: 50% 75 30;
  --destructive-foreground: 6% 0 0;
  --border: 14% 0 0;
  --input: 14% 0 0;
  --ring: 2% 0 0;
  --sidebar-background: 240 5.9% 10%;
  --sidebar-foreground: 240 4.8% 95.9%;
  --sidebar-primary: 224.3 76.3% 48%;
  --sidebar-primary-foreground: 0 0% 100%;
  --sidebar-accent: 240 3.7% 15.9%;
  --sidebar-accent-foreground: 240 4.8% 95.9%;
  --sidebar-border: 240 3.7% 15.9%;
  --sidebar-ring: 0 0% 83.1%;
  --chart-1: 220 70% 50%;
  --chart-2: 160 60% 45%;
  --chart-3: 30 80% 55%;
  --chart-4: 280 65% 60%;
  --chart-5: 340 75% 55%;
}
* {
  --tw-border-opacity: 1;
  border-color: lch(var(--border) / var(--tw-border-opacity, 1));
  -ms-overflow-style: none;
  scrollbar-width: none;
}
*::-webkit-scrollbar {
  display: none;
}
* {
  min-width: 0;
  min-height: 0;
}
html {
  font-size: 15px;
}
body {
  --tw-text-opacity: 1;
  color: lch(var(--foreground) / var(--tw-text-opacity, 1));
}
a {
  color: lch(var(--primary) / 0.6);
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
a:not([type=button]):hover {
  --tw-text-opacity: 1;
  color: lch(var(--primary) / var(--tw-text-opacity, 1));
}
kbd {
  white-space: nowrap;
  border-radius: calc(var(--radius) - 2px);
  border-width: 1px;
  border-bottom-width: 3px;
  --tw-border-opacity: 1;
  border-color: lch(var(--foreground) / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: lch(var(--background) / var(--tw-bg-opacity, 1));
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: lch(var(--foreground) / var(--tw-text-opacity, 1));
  outline-style: solid;
  outline-width: 1px;
  outline-color: lch(var(--background) / 1);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
kbd[data-pressed=true] {
  border-bottom-width: 1px;
}
[data-highlight] em {
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(233 213 255 / var(--tw-bg-opacity, 1));
  font-weight: 700;
  font-style: normal;
}
:focus-visible {
  outline: lch(var(--ring)) auto 1px;
}
::-moz-selection {
  --tw-bg-opacity: 1;
  background-color: rgb(233 213 255 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}
::selection {
  --tw-bg-opacity: 1;
  background-color: rgb(233 213 255 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}
.required-label::after {
  content: "*";
  color: lch(var(--destructive));
  margin-left: 0.25rem;
}
.reset-input {
  margin: 0px;
  height: 2.5rem;
  border-radius: calc(var(--radius) - 2px);
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: lch(var(--border) / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: lch(var(--background) / var(--tw-bg-opacity, 1));
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  --tw-ring-offset-color: lch(var(--accent) / 1);
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.reset-input::file-selector-button {
  border-width: 0px;
  background-color: transparent;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
}
.reset-input::-moz-placeholder {
  --tw-text-opacity: 1;
  color: lch(var(--muted-foreground) / var(--tw-text-opacity, 1));
}
.reset-input::placeholder {
  --tw-text-opacity: 1;
  color: lch(var(--muted-foreground) / var(--tw-text-opacity, 1));
}
.reset-input:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.reset-input:focus-visible {
  --tw-border-opacity: 1;
  border-color: lch(var(--primary) / var(--tw-border-opacity, 1));
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.reset-input:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.reset-input[data-valid=false] {
  --tw-border-opacity: 1 !important;
  border-color: lch(var(--destructive) / var(--tw-border-opacity, 1)) !important;
}
.container {
  width: 100%;
  padding-right: 2rem;
  padding-left: 2rem;
}
@media (min-width: 1400px) {
  .container {
    max-width: 1400px;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.pointer-events-none {
  pointer-events: none;
}
.pointer-events-auto {
  pointer-events: auto;
}
.visible {
  visibility: visible;
}
.invisible {
  visibility: hidden;
}
.static {
  position: static;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.inset-0 {
  inset: 0px;
}
.inset-x-0 {
  left: 0px;
  right: 0px;
}
.inset-y-0 {
  top: 0px;
  bottom: 0px;
}
.-bottom-px {
  bottom: -1px;
}
.bottom-0 {
  bottom: 0px;
}
.left-0 {
  left: 0px;
}
.left-1 {
  left: 0.25rem;
}
.left-1\/2 {
  left: 50%;
}
.left-2 {
  left: 0.5rem;
}
.left-3 {
  left: 0.75rem;
}
.left-\[50\%\] {
  left: 50%;
}
.right-0 {
  right: 0px;
}
.right-0\.5 {
  right: 0.125rem;
}
.right-1 {
  right: 0.25rem;
}
.right-3 {
  right: 0.75rem;
}
.right-4 {
  right: 1rem;
}
.top-0 {
  top: 0px;
}
.top-1\.5 {
  top: 0.375rem;
}
.top-1\/2 {
  top: 50%;
}
.top-2 {
  top: 0.5rem;
}
.top-3\.5 {
  top: 0.875rem;
}
.top-4 {
  top: 1rem;
}
.top-\[1px\] {
  top: 1px;
}
.top-\[50\%\] {
  top: 50%;
}
.top-\[60\%\] {
  top: 60%;
}
.top-full {
  top: 100%;
}
.z-10 {
  z-index: 10;
}
.z-20 {
  z-index: 20;
}
.z-50 {
  z-index: 50;
}
.z-\[1\] {
  z-index: 1;
}
.z-\[60\] {
  z-index: 60;
}
.m-0 {
  margin: 0px;
}
.-mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mx-3\.5 {
  margin-left: 0.875rem;
  margin-right: 0.875rem;
}
.my-0\.5 {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}
.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-2\.5 {
  margin-left: 0.625rem;
}
.ml-\[2px\] {
  margin-left: 2px;
}
.ml-auto {
  margin-left: auto;
}
.mr-2 {
  margin-right: 0.5rem;
}
.ms-2 {
  margin-inline-start: 0.5rem;
}
.mt-1\.5 {
  margin-top: 0.375rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.inline {
  display: inline;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.\!table {
  display: table !important;
}
.table {
  display: table;
}
.grid {
  display: grid;
}
.hidden {
  display: none;
}
.aspect-square {
  aspect-ratio: 1 / 1;
}
.aspect-video {
  aspect-ratio: 16 / 9;
}
.size-2 {
  width: 0.5rem;
  height: 0.5rem;
}
.size-3 {
  width: 0.75rem;
  height: 0.75rem;
}
.size-4 {
  width: 1rem;
  height: 1rem;
}
.size-5 {
  width: 1.25rem;
  height: 1.25rem;
}
.size-6 {
  width: 1.5rem;
  height: 1.5rem;
}
.size-8 {
  width: 2rem;
  height: 2rem;
}
.size-full {
  width: 100%;
  height: 100%;
}
.h-1\.5 {
  height: 0.375rem;
}
.h-10 {
  height: 2.5rem;
}
.h-11 {
  height: 2.75rem;
}
.h-12 {
  height: 3rem;
}
.h-14 {
  height: 3.5rem;
}
.h-2 {
  height: 0.5rem;
}
.h-2\.5 {
  height: 0.625rem;
}
.h-24 {
  height: 6rem;
}
.h-3 {
  height: 0.75rem;
}
.h-3\.5 {
  height: 0.875rem;
}
.h-4 {
  height: 1rem;
}
.h-5 {
  height: 1.25rem;
}
.h-6 {
  height: 1.5rem;
}
.h-7 {
  height: 1.75rem;
}
.h-8 {
  height: 2rem;
}
.h-9 {
  height: 2.25rem;
}
.h-\[1px\] {
  height: 1px;
}
.h-\[var\(--cmdk-list-height\)\] {
  height: var(--cmdk-list-height);
}
.h-\[var\(--radix-navigation-menu-viewport-height\)\] {
  height: var(--radix-navigation-menu-viewport-height);
}
.h-\[var\(--radix-select-trigger-height\)\] {
  height: var(--radix-select-trigger-height);
}
.h-auto {
  height: auto;
}
.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}
.h-full {
  height: 100%;
}
.h-px {
  height: 1px;
}
.h-svh {
  height: 100svh;
}
.max-h-96 {
  max-height: 24rem;
}
.max-h-\[350px\] {
  max-height: 350px;
}
.max-h-svh {
  max-height: 100svh;
}
.min-h-0 {
  min-height: 0px;
}
.min-h-fit {
  min-height: -moz-fit-content;
  min-height: fit-content;
}
.min-h-svh {
  min-height: 100svh;
}
.w-0 {
  width: 0px;
}
.w-0\.5 {
  width: 0.125rem;
}
.w-1 {
  width: 0.25rem;
}
.w-10 {
  width: 2.5rem;
}
.w-12 {
  width: 3rem;
}
.w-14 {
  width: 3.5rem;
}
.w-2 {
  width: 0.5rem;
}
.w-2\.5 {
  width: 0.625rem;
}
.w-3 {
  width: 0.75rem;
}
.w-3\.5 {
  width: 0.875rem;
}
.w-3\/4 {
  width: 75%;
}
.w-4 {
  width: 1rem;
}
.w-5 {
  width: 1.25rem;
}
.w-6 {
  width: 1.5rem;
}
.w-64 {
  width: 16rem;
}
.w-7 {
  width: 1.75rem;
}
.w-72 {
  width: 18rem;
}
.w-8 {
  width: 2rem;
}
.w-9 {
  width: 2.25rem;
}
.w-\[--sidebar-width\] {
  width: var(--sidebar-width);
}
.w-\[100px\] {
  width: 100px;
}
.w-\[1px\] {
  width: 1px;
}
.w-\[70px\] {
  width: 70px;
}
.w-auto {
  width: auto;
}
.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}
.w-full {
  width: 100%;
}
.w-max {
  width: -moz-max-content;
  width: max-content;
}
.w-px {
  width: 1px;
}
.min-w-0 {
  min-width: 0px;
}
.min-w-10 {
  min-width: 2.5rem;
}
.min-w-48 {
  min-width: 12rem;
}
.min-w-5 {
  min-width: 1.25rem;
}
.min-w-\[8rem\] {
  min-width: 8rem;
}
.min-w-\[var\(--radix-select-trigger-width\)\] {
  min-width: var(--radix-select-trigger-width);
}
.min-w-fit {
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.max-w-\[--skeleton-width\] {
  max-width: var(--skeleton-width);
}
.max-w-full {
  max-width: 100%;
}
.max-w-lg {
  max-width: 32rem;
}
.max-w-max {
  max-width: -moz-max-content;
  max-width: max-content;
}
.max-w-xs {
  max-width: 20rem;
}
.flex-1 {
  flex: 1 1 0%;
}
.shrink-0 {
  flex-shrink: 0;
}
.table-auto {
  table-layout: auto;
}
.caption-bottom {
  caption-side: bottom;
}
.border-collapse {
  border-collapse: collapse;
}
.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-\[-50\%\] {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-\[var\(--wobble-x\)\] {
  --tw-translate-x: var(--wobble-x);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-px {
  --tw-translate-x: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-3 {
  --tw-translate-y: 0.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[-50\%\] {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[var\(--wobble-y\)\] {
  --tw-translate-y: var(--wobble-y);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-full {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-\[1\.01\] {
  --tw-scale-x: 1.01;
  --tw-scale-y: 1.01;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-\[1\.02\] {
  --tw-scale-x: 1.02;
  --tw-scale-y: 1.02;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-\[1\.1\] {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-\[1\] {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
.animate-bounce {
  animation: bounce 1s infinite;
}
@keyframes pulse {
  50% {
    opacity: .5;
  }
}
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.animate-spin {
  animation: spin 1s linear infinite;
}
.cursor-default {
  cursor: default;
}
.cursor-pointer {
  cursor: pointer;
}
.touch-none {
  touch-action: none;
}
.select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.resize-none {
  resize: none;
}
.list-none {
  list-style-type: none;
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.flex-col {
  flex-direction: column;
}
.flex-col-reverse {
  flex-direction: column-reverse;
}
.flex-wrap {
  flex-wrap: wrap;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-stretch {
  align-items: stretch;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-1\.5 {
  gap: 0.375rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-4 {
  gap: 1rem;
}
.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-\[2px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2px * var(--tw-space-x-reverse));
  margin-left: calc(2px * calc(1 - var(--tw-space-x-reverse)));
}
.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
.space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}
.self-start {
  align-self: flex-start;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overscroll-contain {
  overscroll-behavior: contain;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-ellipsis {
  text-overflow: ellipsis;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.whitespace-pre-wrap {
  white-space: pre-wrap;
}
.break-words {
  overflow-wrap: break-word;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-2xl {
  border-radius: 1rem;
}
.rounded-3xl {
  border-radius: 1.5rem;
}
.rounded-\[2px\] {
  border-radius: 2px;
}
.rounded-\[inherit\] {
  border-radius: inherit;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-lg {
  border-radius: var(--radius);
}
.rounded-md {
  border-radius: calc(var(--radius) - 2px);
}
.rounded-none {
  border-radius: 0px;
}
.rounded-sm {
  border-radius: calc(var(--radius) - 4px);
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded-tl-sm {
  border-top-left-radius: calc(var(--radius) - 4px);
}
.border {
  border-width: 1px;
}
.border-0 {
  border-width: 0px;
}
.border-2 {
  border-width: 2px;
}
.border-\[1\.5px\] {
  border-width: 1.5px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-b-\[3px\] {
  border-bottom-width: 3px;
}
.border-l {
  border-left-width: 1px;
}
.border-r {
  border-right-width: 1px;
}
.border-t {
  border-top-width: 1px;
}
.border-solid {
  border-style: solid;
}
.border-dashed {
  border-style: dashed;
}
.border-none {
  border-style: none;
}
.border-\[--color-border\] {
  border-color: var(--color-border);
}
.border-amber-400 {
  --tw-border-opacity: 1;
  border-color: rgb(251 191 36 / var(--tw-border-opacity, 1));
}
.border-amber-500 {
  --tw-border-opacity: 1;
  border-color: rgb(245 158 11 / var(--tw-border-opacity, 1));
}
.border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity, 1));
}
.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity, 1));
}
.border-border {
  --tw-border-opacity: 1;
  border-color: lch(var(--border) / var(--tw-border-opacity, 1));
}
.border-border\/50 {
  border-color: lch(var(--border) / 0.5);
}
.border-destructive {
  --tw-border-opacity: 1;
  border-color: lch(var(--destructive) / var(--tw-border-opacity, 1));
}
.border-destructive\/20 {
  border-color: lch(var(--destructive) / 0.2);
}
.border-emerald-500 {
  --tw-border-opacity: 1;
  border-color: rgb(16 185 129 / var(--tw-border-opacity, 1));
}
.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity, 1));
}
.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity, 1));
}
.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity, 1));
}
.border-input {
  --tw-border-opacity: 1;
  border-color: lch(var(--input) / var(--tw-border-opacity, 1));
}
.border-muted-foreground\/50 {
  border-color: lch(var(--muted-foreground) / 0.5);
}
.border-primary {
  --tw-border-opacity: 1;
  border-color: lch(var(--primary) / var(--tw-border-opacity, 1));
}
.border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity, 1));
}
.border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgb(147 51 234 / var(--tw-border-opacity, 1));
}
.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity, 1));
}
.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity, 1));
}
.border-rose-400 {
  --tw-border-opacity: 1;
  border-color: rgb(251 113 133 / var(--tw-border-opacity, 1));
}
.border-transparent {
  border-color: transparent;
}
.border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgb(234 179 8 / var(--tw-border-opacity, 1));
}
.border-l-transparent {
  border-left-color: transparent;
}
.border-t-transparent {
  border-top-color: transparent;
}
.border-opacity-40 {
  --tw-border-opacity: 0.4;
}
.bg-\[--color-bg\] {
  background-color: var(--color-bg);
}
.bg-accent {
  --tw-bg-opacity: 1;
  background-color: lch(var(--accent) / var(--tw-bg-opacity, 1));
}
.bg-accent-foreground\/40 {
  background-color: lch(var(--accent-foreground) / 0.4);
}
.bg-amber-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 243 199 / var(--tw-bg-opacity, 1));
}
.bg-amber-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity, 1));
}
.bg-amber-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 119 6 / var(--tw-bg-opacity, 1));
}
.bg-background {
  --tw-bg-opacity: 1;
  background-color: lch(var(--background) / var(--tw-bg-opacity, 1));
}
.bg-background\/50 {
  background-color: lch(var(--background) / 0.5);
}
.bg-background\/70 {
  background-color: lch(var(--background) / 0.7);
}
.bg-background\/80 {
  background-color: lch(var(--background) / 0.8);
}
.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity, 1));
}
.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity, 1));
}
.bg-border {
  --tw-bg-opacity: 1;
  background-color: lch(var(--border) / var(--tw-bg-opacity, 1));
}
.bg-border\/50 {
  background-color: lch(var(--border) / 0.5);
}
.bg-card {
  --tw-bg-opacity: 1;
  background-color: lch(var(--card) / var(--tw-bg-opacity, 1));
}
.bg-destructive {
  --tw-bg-opacity: 1;
  background-color: lch(var(--destructive) / var(--tw-bg-opacity, 1));
}
.bg-destructive\/10 {
  background-color: lch(var(--destructive) / 0.1);
}
.bg-destructive\/5 {
  background-color: lch(var(--destructive) / 0.05);
}
.bg-destructive\/80 {
  background-color: lch(var(--destructive) / 0.8);
}
.bg-emerald-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 250 229 / var(--tw-bg-opacity, 1));
}
.bg-emerald-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(5 150 105 / var(--tw-bg-opacity, 1));
}
.bg-foreground {
  --tw-bg-opacity: 1;
  background-color: lch(var(--foreground) / var(--tw-bg-opacity, 1));
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}
.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity, 1));
}
.bg-muted {
  --tw-bg-opacity: 1;
  background-color: lch(var(--muted) / var(--tw-bg-opacity, 1));
}
.bg-muted\/50 {
  background-color: lch(var(--muted) / 0.5);
}
.bg-popover {
  --tw-bg-opacity: 1;
  background-color: lch(var(--popover) / var(--tw-bg-opacity, 1));
}
.bg-popover\/80 {
  background-color: lch(var(--popover) / 0.8);
}
.bg-primary {
  --tw-bg-opacity: 1;
  background-color: lch(var(--primary) / var(--tw-bg-opacity, 1));
}
.bg-primary\/60 {
  background-color: lch(var(--primary) / 0.6);
}
.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity, 1));
}
.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity, 1));
}
.bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity, 1));
}
.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity, 1));
}
.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity, 1));
}
.bg-rose-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 63 94 / var(--tw-bg-opacity, 1));
}
.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: lch(var(--secondary) / var(--tw-bg-opacity, 1));
}
.bg-sidebar {
  background-color: hsl(var(--sidebar-background));
}
.bg-sidebar-border {
  background-color: hsl(var(--sidebar-border));
}
.bg-transparent {
  background-color: transparent;
}
.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity, 1));
}
.fill-current {
  fill: currentColor;
}
.\!p-0 {
  padding: 0px !important;
}
.p-0 {
  padding: 0px;
}
.p-0\.5 {
  padding: 0.125rem;
}
.p-1 {
  padding: 0.25rem;
}
.p-1\.5 {
  padding: 0.375rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-3 {
  padding: 0.75rem;
}
.p-4 {
  padding: 1rem;
}
.p-6 {
  padding: 1.5rem;
}
.p-\[1px\] {
  padding: 1px;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-1\.5 {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pb-3 {
  padding-bottom: 0.75rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pl-1\.5 {
  padding-left: 0.375rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pl-3 {
  padding-left: 0.75rem;
}
.pl-8 {
  padding-left: 2rem;
}
.pl-9 {
  padding-left: 2.25rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pr-2\.5 {
  padding-right: 0.625rem;
}
.pr-8 {
  padding-right: 2rem;
}
.pt-0 {
  padding-top: 0px;
}
.pt-1 {
  padding-top: 0.25rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.pt-6 {
  padding-top: 1.5rem;
}
.text-left {
  text-align: left;
}
.\!text-center {
  text-align: center !important;
}
.text-center {
  text-align: center;
}
.text-start {
  text-align: start;
}
.align-middle {
  vertical-align: middle;
}
.font-mono {
  font-family:
    ui-monospace,
    SFMono-Regular,
    Menlo,
    Monaco,
    Consolas,
    "Liberation Mono",
    "Courier New",
    monospace;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.text-5xl {
  font-size: 3rem;
  line-height: 1;
}
.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}
.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}
.text-8xl {
  font-size: 6rem;
  line-height: 1;
}
.text-9xl {
  font-size: 8rem;
  line-height: 1;
}
.text-\[0\.8rem\] {
  font-size: 0.8rem;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-tiny {
  font-size: 0.625rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.font-bold {
  font-weight: 700;
}
.font-extrabold {
  font-weight: 800;
}
.font-medium {
  font-weight: 500;
}
.font-normal {
  font-weight: 400;
}
.font-semibold {
  font-weight: 600;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}
.italic {
  font-style: italic;
}
.tabular-nums {
  --tw-numeric-spacing: tabular-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}
.leading-none {
  line-height: 1;
}
.tracking-tight {
  letter-spacing: -0.025em;
}
.tracking-widest {
  letter-spacing: 0.1em;
}
.text-accent-foreground {
  --tw-text-opacity: 1;
  color: lch(var(--accent-foreground) / var(--tw-text-opacity, 1));
}
.text-amber-50 {
  --tw-text-opacity: 1;
  color: rgb(255 251 235 / var(--tw-text-opacity, 1));
}
.text-amber-700 {
  --tw-text-opacity: 1;
  color: rgb(180 83 9 / var(--tw-text-opacity, 1));
}
.text-amber-900 {
  --tw-text-opacity: 1;
  color: rgb(120 53 15 / var(--tw-text-opacity, 1));
}
.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity, 1));
}
.text-blue-900 {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity, 1));
}
.text-card-foreground {
  --tw-text-opacity: 1;
  color: lch(var(--card-foreground) / var(--tw-text-opacity, 1));
}
.text-destructive {
  --tw-text-opacity: 1;
  color: lch(var(--destructive) / var(--tw-text-opacity, 1));
}
.text-destructive-foreground {
  --tw-text-opacity: 1;
  color: lch(var(--destructive-foreground) / var(--tw-text-opacity, 1));
}
.text-emerald-50 {
  --tw-text-opacity: 1;
  color: rgb(236 253 245 / var(--tw-text-opacity, 1));
}
.text-emerald-700 {
  --tw-text-opacity: 1;
  color: rgb(4 120 87 / var(--tw-text-opacity, 1));
}
.text-foreground {
  --tw-text-opacity: 1;
  color: lch(var(--foreground) / var(--tw-text-opacity, 1));
}
.text-green-900 {
  --tw-text-opacity: 1;
  color: rgb(20 83 45 / var(--tw-text-opacity, 1));
}
.text-muted-foreground {
  --tw-text-opacity: 1;
  color: lch(var(--muted-foreground) / var(--tw-text-opacity, 1));
}
.text-popover-foreground {
  --tw-text-opacity: 1;
  color: lch(var(--popover-foreground) / var(--tw-text-opacity, 1));
}
.text-primary {
  --tw-text-opacity: 1;
  color: lch(var(--primary) / var(--tw-text-opacity, 1));
}
.text-primary-foreground {
  --tw-text-opacity: 1;
  color: lch(var(--primary-foreground) / var(--tw-text-opacity, 1));
}
.text-purple-50 {
  --tw-text-opacity: 1;
  color: rgb(250 245 255 / var(--tw-text-opacity, 1));
}
.text-purple-600 {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity, 1));
}
.text-purple-700 {
  --tw-text-opacity: 1;
  color: rgb(126 34 206 / var(--tw-text-opacity, 1));
}
.text-purple-900 {
  --tw-text-opacity: 1;
  color: rgb(88 28 135 / var(--tw-text-opacity, 1));
}
.text-secondary-foreground {
  --tw-text-opacity: 1;
  color: lch(var(--secondary-foreground) / var(--tw-text-opacity, 1));
}
.text-sidebar-foreground {
  color: hsl(var(--sidebar-foreground));
}
.text-sidebar-foreground\/70 {
  color: hsl(var(--sidebar-foreground) / 0.7);
}
.text-slate-800 {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity, 1));
}
.underline {
  text-decoration-line: underline;
}
.line-through {
  text-decoration-line: line-through;
}
.underline-offset-4 {
  text-underline-offset: 4px;
}
.opacity-0 {
  opacity: 0;
}
.opacity-100 {
  opacity: 1;
}
.opacity-40 {
  opacity: 0.4;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-70 {
  opacity: 0.7;
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.outline {
  outline-style: solid;
}
.outline-1 {
  outline-width: 1px;
}
.outline-transparent {
  outline-color: transparent;
}
.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:
    var(--tw-ring-offset-shadow),
    var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.ring-sidebar-ring {
  --tw-ring-color: hsl(var(--sidebar-ring));
}
.ring-offset-accent {
  --tw-ring-offset-color: lch(var(--accent) / 1);
}
.ring-offset-background {
  --tw-ring-offset-color: lch(var(--background) / 1);
}
.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.transition {
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    opacity,
    box-shadow,
    transform,
    filter,
    -webkit-backdrop-filter;
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    opacity,
    box-shadow,
    transform,
    filter,
    backdrop-filter;
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    opacity,
    box-shadow,
    transform,
    filter,
    backdrop-filter,
    -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-\[height\] {
  transition-property: height;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-\[left\,right\,width\] {
  transition-property:
    left,
    right,
    width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-\[margin\,opa\] {
  transition-property: margin, opa;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-\[width\] {
  transition-property: width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-colors {
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.duration-100 {
  transition-duration: 100ms;
}
.duration-200 {
  transition-duration: 200ms;
}
.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
@keyframes enter {
  from {
    opacity: var(--tw-enter-opacity, 1);
    transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0));
  }
}
@keyframes exit {
  to {
    opacity: var(--tw-exit-opacity, 1);
    transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0));
  }
}
.animate-in {
  animation-name: enter;
  animation-duration: 150ms;
  --tw-enter-opacity: initial;
  --tw-enter-scale: initial;
  --tw-enter-rotate: initial;
  --tw-enter-translate-x: initial;
  --tw-enter-translate-y: initial;
}
.fade-in {
  --tw-enter-opacity: 0;
}
.fade-in-0 {
  --tw-enter-opacity: 0;
}
.zoom-in-95 {
  --tw-enter-scale: .95;
}
.zoom-out-95 {
  --tw-exit-scale: .95;
}
.duration-100 {
  animation-duration: 100ms;
}
.duration-200 {
  animation-duration: 200ms;
}
.ease-in-out {
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-out {
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.glass {
  background-color: lch(var(--background) / 0.8);
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  --tw-backdrop-blur: blur(12px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.animation-open-close[data-open=true] {
  animation-name: enter;
  animation-duration: 150ms;
  --tw-enter-opacity: initial;
  --tw-enter-scale: initial;
  --tw-enter-rotate: initial;
  --tw-enter-translate-x: initial;
  --tw-enter-translate-y: initial;
  --tw-enter-opacity: 0;
  --tw-enter-scale: .95;
}
.animation-open-close[data-open=false] {
  animation-name: exit;
  animation-duration: 150ms;
  --tw-exit-opacity: initial;
  --tw-exit-scale: initial;
  --tw-exit-rotate: initial;
  --tw-exit-translate-x: initial;
  --tw-exit-translate-y: initial;
  --tw-exit-opacity: 0;
  --tw-exit-scale: .95;
}
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.file\:border-0::file-selector-button {
  border-width: 0px;
}
.file\:bg-transparent::file-selector-button {
  background-color: transparent;
}
.file\:text-sm::file-selector-button {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.file\:font-medium::file-selector-button {
  font-weight: 500;
}
.file\:text-foreground::file-selector-button {
  --tw-text-opacity: 1;
  color: lch(var(--foreground) / var(--tw-text-opacity, 1));
}
.placeholder\:text-muted-foreground::-moz-placeholder {
  --tw-text-opacity: 1;
  color: lch(var(--muted-foreground) / var(--tw-text-opacity, 1));
}
.placeholder\:text-muted-foreground::placeholder {
  --tw-text-opacity: 1;
  color: lch(var(--muted-foreground) / var(--tw-text-opacity, 1));
}
.after\:absolute::after {
  content: var(--tw-content);
  position: absolute;
}
.after\:-inset-2::after {
  content: var(--tw-content);
  inset: -0.5rem;
}
.after\:inset-y-0::after {
  content: var(--tw-content);
  top: 0px;
  bottom: 0px;
}
.after\:left-1\/2::after {
  content: var(--tw-content);
  left: 50%;
}
.after\:w-1::after {
  content: var(--tw-content);
  width: 0.25rem;
}
.after\:w-\[2px\]::after {
  content: var(--tw-content);
  width: 2px;
}
.after\:-translate-x-1\/2::after {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.focus-within\:relative:focus-within {
  position: relative;
}
.focus-within\:z-20:focus-within {
  z-index: 20;
}
.focus-within\:outline-0:focus-within {
  outline-width: 0px;
}
.focus-within\:ring-0:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:
    var(--tw-ring-offset-shadow),
    var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.hover\:\!bg-primary:hover {
  --tw-bg-opacity: 1 !important;
  background-color: lch(var(--primary) / var(--tw-bg-opacity, 1)) !important;
}
.hover\:bg-accent:hover {
  --tw-bg-opacity: 1;
  background-color: lch(var(--accent) / var(--tw-bg-opacity, 1));
}
.hover\:bg-accent\/0:hover {
  background-color: lch(var(--accent) / 0);
}
.hover\:bg-amber-600\/80:hover {
  background-color: rgb(217 119 6 / 0.8);
}
.hover\:bg-background:hover {
  --tw-bg-opacity: 1;
  background-color: lch(var(--background) / var(--tw-bg-opacity, 1));
}
.hover\:bg-destructive:hover {
  --tw-bg-opacity: 1;
  background-color: lch(var(--destructive) / var(--tw-bg-opacity, 1));
}
.hover\:bg-destructive\/80:hover {
  background-color: lch(var(--destructive) / 0.8);
}
.hover\:bg-destructive\/90:hover {
  background-color: lch(var(--destructive) / 0.9);
}
.hover\:bg-emerald-600\/80:hover {
  background-color: rgb(5 150 105 / 0.8);
}
.hover\:bg-muted:hover {
  --tw-bg-opacity: 1;
  background-color: lch(var(--muted) / var(--tw-bg-opacity, 1));
}
.hover\:bg-muted\/50:hover {
  background-color: lch(var(--muted) / 0.5);
}
.hover\:bg-primary:hover {
  --tw-bg-opacity: 1;
  background-color: lch(var(--primary) / var(--tw-bg-opacity, 1));
}
.hover\:bg-primary\/80:hover {
  background-color: lch(var(--primary) / 0.8);
}
.hover\:bg-primary\/90:hover {
  background-color: lch(var(--primary) / 0.9);
}
.hover\:bg-purple-600\/80:hover {
  background-color: rgb(147 51 234 / 0.8);
}
.hover\:bg-secondary\/80:hover {
  background-color: lch(var(--secondary) / 0.8);
}
.hover\:bg-sidebar-accent:hover {
  background-color: hsl(var(--sidebar-accent));
}
.hover\:text-accent-foreground:hover {
  --tw-text-opacity: 1;
  color: lch(var(--accent-foreground) / var(--tw-text-opacity, 1));
}
.hover\:text-destructive:hover {
  --tw-text-opacity: 1;
  color: lch(var(--destructive) / var(--tw-text-opacity, 1));
}
.hover\:text-foreground:hover {
  --tw-text-opacity: 1;
  color: lch(var(--foreground) / var(--tw-text-opacity, 1));
}
.hover\:text-muted-foreground:hover {
  --tw-text-opacity: 1;
  color: lch(var(--muted-foreground) / var(--tw-text-opacity, 1));
}
.hover\:text-primary-foreground:hover {
  --tw-text-opacity: 1;
  color: lch(var(--primary-foreground) / var(--tw-text-opacity, 1));
}
.hover\:text-secondary-foreground:hover {
  --tw-text-opacity: 1;
  color: lch(var(--secondary-foreground) / var(--tw-text-opacity, 1));
}
.hover\:text-sidebar-accent-foreground:hover {
  color: hsl(var(--sidebar-accent-foreground));
}
.hover\:underline:hover {
  text-decoration-line: underline;
}
.hover\:opacity-100:hover {
  opacity: 1;
}
.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.hover\:outline-border:hover {
  outline-color: lch(var(--border) / 1);
}
.hover\:after\:bg-sidebar-border:hover::after {
  content: var(--tw-content);
  background-color: hsl(var(--sidebar-border));
}
.focus\:bg-accent:focus {
  --tw-bg-opacity: 1;
  background-color: lch(var(--accent) / var(--tw-bg-opacity, 1));
}
.focus\:bg-primary:focus {
  --tw-bg-opacity: 1;
  background-color: lch(var(--primary) / var(--tw-bg-opacity, 1));
}
.focus\:text-accent-foreground:focus {
  --tw-text-opacity: 1;
  color: lch(var(--accent-foreground) / var(--tw-text-opacity, 1));
}
.focus\:text-primary-foreground:focus {
  --tw-text-opacity: 1;
  color: lch(var(--primary-foreground) / var(--tw-text-opacity, 1));
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-0:focus {
  outline-width: 0px;
}
.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:
    var(--tw-ring-offset-shadow),
    var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:
    var(--tw-ring-offset-shadow),
    var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus\:ring-ring:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: lch(var(--ring) / var(--tw-ring-opacity, 1));
}
.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}
.focus-visible\:border-primary:focus-visible {
  --tw-border-opacity: 1;
  border-color: lch(var(--primary) / var(--tw-border-opacity, 1));
}
.focus-visible\:outline-none:focus-visible {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus-visible\:outline-0:focus-visible {
  outline-width: 0px;
}
.focus-visible\:ring-0:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:
    var(--tw-ring-offset-shadow),
    var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus-visible\:ring-1:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:
    var(--tw-ring-offset-shadow),
    var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus-visible\:ring-2:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:
    var(--tw-ring-offset-shadow),
    var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus-visible\:ring-ring:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: lch(var(--ring) / var(--tw-ring-opacity, 1));
}
.focus-visible\:ring-sidebar-ring:focus-visible {
  --tw-ring-color: hsl(var(--sidebar-ring));
}
.focus-visible\:ring-offset-1:focus-visible {
  --tw-ring-offset-width: 1px;
}
.focus-visible\:ring-offset-2:focus-visible {
  --tw-ring-offset-width: 2px;
}
.focus-visible\:ring-offset-background:focus-visible {
  --tw-ring-offset-color: lch(var(--background) / 1);
}
.active\:scale-95:active {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.active\:border-b-\[1px\]:active {
  border-bottom-width: 1px;
}
.active\:bg-sidebar-accent:active {
  background-color: hsl(var(--sidebar-accent));
}
.active\:text-sidebar-accent-foreground:active {
  color: hsl(var(--sidebar-accent-foreground));
}
.active\:shadow-none:active {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.active\:outline-border:active {
  outline-color: lch(var(--border) / 1);
}
.hover\:active\:scale-95:active:hover {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.hover\:active\:border-b-\[1px\]:active:hover {
  border-bottom-width: 1px;
}
.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}
.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}
.disabled\:opacity-50:disabled {
  opacity: 0.5;
}
.group\/item:last-of-type .group-last-of-type\/item\:mb-0 {
  margin-bottom: 0px;
}
.group\/item:last-of-type .group-last-of-type\/item\:hidden {
  display: none;
}
.group\/menu-item:focus-within .group-focus-within\/menu-item\:opacity-100 {
  opacity: 1;
}
.group\/menu-item:hover .group-hover\/menu-item\:opacity-100 {
  opacity: 1;
}
.group.toaster .group-\[\.toaster\]\:rounded-3xl {
  border-radius: 1.5rem;
}
.group.toaster .group-\[\.toaster\]\:border-border {
  --tw-border-opacity: 1;
  border-color: lch(var(--border) / var(--tw-border-opacity, 1));
}
.group.toast .group-\[\.toast\]\:bg-background {
  --tw-bg-opacity: 1;
  background-color: lch(var(--background) / var(--tw-bg-opacity, 1));
}
.group.toast .group-\[\.toast\]\:bg-muted {
  --tw-bg-opacity: 1;
  background-color: lch(var(--muted) / var(--tw-bg-opacity, 1));
}
.group.toast .group-\[\.toast\]\:bg-primary {
  --tw-bg-opacity: 1;
  background-color: lch(var(--primary) / var(--tw-bg-opacity, 1));
}
.group.toaster .group-\[\.toaster\]\:bg-background {
  --tw-bg-opacity: 1;
  background-color: lch(var(--background) / var(--tw-bg-opacity, 1));
}
.group.toast .group-\[\.toast\]\:text-foreground {
  --tw-text-opacity: 1;
  color: lch(var(--foreground) / var(--tw-text-opacity, 1));
}
.group.toast .group-\[\.toast\]\:text-muted-foreground {
  --tw-text-opacity: 1;
  color: lch(var(--muted-foreground) / var(--tw-text-opacity, 1));
}
.group.toast .group-\[\.toast\]\:text-primary-foreground {
  --tw-text-opacity: 1;
  color: lch(var(--primary-foreground) / var(--tw-text-opacity, 1));
}
.group.toaster .group-\[\.toaster\]\:text-foreground {
  --tw-text-opacity: 1;
  color: lch(var(--foreground) / var(--tw-text-opacity, 1));
}
.group.toaster .group-\[\.toaster\]\:shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.peer\/menu-button:hover ~ .peer-hover\/menu-button\:text-sidebar-accent-foreground {
  color: hsl(var(--sidebar-accent-foreground));
}
.peer:disabled ~ .peer-disabled\:cursor-not-allowed {
  cursor: not-allowed;
}
.peer:disabled ~ .peer-disabled\:opacity-70 {
  opacity: 0.7;
}
.has-\[\[data-variant\=inset\]\]\:bg-sidebar:has([data-variant=inset]) {
  background-color: hsl(var(--sidebar-background));
}
.group\/menu-item:has([data-sidebar=menu-action]) .group-has-\[\[data-sidebar\=menu-action\]\]\/menu-item\:pr-8 {
  padding-right: 2rem;
}
.aria-disabled\:pointer-events-none[aria-disabled=true] {
  pointer-events: none;
}
.aria-disabled\:opacity-50[aria-disabled=true] {
  opacity: 0.5;
}
.aria-expanded\:bg-background[aria-expanded=true] {
  --tw-bg-opacity: 1;
  background-color: lch(var(--background) / var(--tw-bg-opacity, 1));
}
.aria-selected\:bg-accent[aria-selected=true] {
  --tw-bg-opacity: 1;
  background-color: lch(var(--accent) / var(--tw-bg-opacity, 1));
}
.aria-selected\:bg-accent\/50[aria-selected=true] {
  background-color: lch(var(--accent) / 0.5);
}
.aria-selected\:text-accent-foreground[aria-selected=true] {
  --tw-text-opacity: 1;
  color: lch(var(--accent-foreground) / var(--tw-text-opacity, 1));
}
.aria-selected\:text-muted-foreground[aria-selected=true] {
  --tw-text-opacity: 1;
  color: lch(var(--muted-foreground) / var(--tw-text-opacity, 1));
}
.aria-selected\:opacity-100[aria-selected=true] {
  opacity: 1;
}
.aria-selected\:opacity-30[aria-selected=true] {
  opacity: 0.3;
}
.data-\[disabled\=true\]\:pointer-events-none[data-disabled=true] {
  pointer-events: none;
}
.data-\[disabled\]\:pointer-events-none[data-disabled] {
  pointer-events: none;
}
.data-\[panel-group-direction\=vertical\]\:h-px[data-panel-group-direction=vertical] {
  height: 1px;
}
.data-\[panel-group-direction\=vertical\]\:w-full[data-panel-group-direction=vertical] {
  width: 100%;
}
.data-\[side\=bottom\]\:translate-y-1[data-side=bottom] {
  --tw-translate-y: 0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[side\=left\]\:-translate-x-1[data-side=left] {
  --tw-translate-x: -0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[side\=right\]\:translate-x-1[data-side=right] {
  --tw-translate-x: 0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[side\=top\]\:-translate-y-1[data-side=top] {
  --tw-translate-y: -0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[state\=checked\]\:translate-x-4[data-state=checked] {
  --tw-translate-x: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[state\=unchecked\]\:translate-x-0[data-state=unchecked] {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes accordion-up {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}
.data-\[state\=closed\]\:animate-accordion-up[data-state=closed] {
  animation: accordion-up 100ms ease;
}
@keyframes collapsible-up {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
}
.data-\[state\=closed\]\:animate-collapsible-up[data-state=closed] {
  animation: collapsible-up 100ms ease;
}
@keyframes accordion-down {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}
.data-\[state\=open\]\:animate-accordion-down[data-state=open] {
  animation: accordion-down 100ms ease;
}
@keyframes collapsible-down {
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}
.data-\[state\=open\]\:animate-collapsible-down[data-state=open] {
  animation: collapsible-down 100ms ease;
}
.data-\[panel-group-direction\=vertical\]\:flex-col[data-panel-group-direction=vertical] {
  flex-direction: column;
}
.data-\[state\=active\]\:border-border[data-state=active] {
  --tw-border-opacity: 1;
  border-color: lch(var(--border) / var(--tw-border-opacity, 1));
}
.data-\[valid\=false\]\:\!border-destructive[data-valid=false] {
  --tw-border-opacity: 1 !important;
  border-color: lch(var(--destructive) / var(--tw-border-opacity, 1)) !important;
}
.data-\[active\=true\]\:bg-sidebar-accent[data-active=true] {
  background-color: hsl(var(--sidebar-accent));
}
.data-\[active\]\:bg-accent\/50[data-active] {
  background-color: lch(var(--accent) / 0.5);
}
.data-\[selected\=true\]\:bg-accent[data-selected=true] {
  --tw-bg-opacity: 1;
  background-color: lch(var(--accent) / var(--tw-bg-opacity, 1));
}
.data-\[state\=active\]\:bg-muted[data-state=active] {
  --tw-bg-opacity: 1;
  background-color: lch(var(--muted) / var(--tw-bg-opacity, 1));
}
.data-\[state\=checked\]\:bg-accent[data-state=checked] {
  --tw-bg-opacity: 1;
  background-color: lch(var(--accent) / var(--tw-bg-opacity, 1));
}
.data-\[state\=checked\]\:bg-primary[data-state=checked] {
  --tw-bg-opacity: 1;
  background-color: lch(var(--primary) / var(--tw-bg-opacity, 1));
}
.data-\[state\=on\]\:bg-accent[data-state=on] {
  --tw-bg-opacity: 1;
  background-color: lch(var(--accent) / var(--tw-bg-opacity, 1));
}
.data-\[state\=on\]\:bg-primary[data-state=on] {
  --tw-bg-opacity: 1;
  background-color: lch(var(--primary) / var(--tw-bg-opacity, 1));
}
.data-\[state\=open\]\:bg-accent[data-state=open] {
  --tw-bg-opacity: 1;
  background-color: lch(var(--accent) / var(--tw-bg-opacity, 1));
}
.data-\[state\=open\]\:bg-accent\/50[data-state=open] {
  background-color: lch(var(--accent) / 0.5);
}
.data-\[state\=open\]\:bg-secondary[data-state=open] {
  --tw-bg-opacity: 1;
  background-color: lch(var(--secondary) / var(--tw-bg-opacity, 1));
}
.data-\[state\=selected\]\:bg-muted[data-state=selected] {
  --tw-bg-opacity: 1;
  background-color: lch(var(--muted) / var(--tw-bg-opacity, 1));
}
.data-\[state\=unchecked\]\:bg-input[data-state=unchecked] {
  --tw-bg-opacity: 1;
  background-color: lch(var(--input) / var(--tw-bg-opacity, 1));
}
.data-\[active\=true\]\:font-medium[data-active=true] {
  font-weight: 500;
}
.data-\[active\=true\]\:text-sidebar-accent-foreground[data-active=true] {
  color: hsl(var(--sidebar-accent-foreground));
}
.data-\[selected\=true\]\:text-accent-foreground[data-selected=true] {
  --tw-text-opacity: 1;
  color: lch(var(--accent-foreground) / var(--tw-text-opacity, 1));
}
.data-\[state\=active\]\:text-foreground[data-state=active] {
  --tw-text-opacity: 1;
  color: lch(var(--foreground) / var(--tw-text-opacity, 1));
}
.data-\[state\=checked\]\:text-primary-foreground[data-state=checked] {
  --tw-text-opacity: 1;
  color: lch(var(--primary-foreground) / var(--tw-text-opacity, 1));
}
.data-\[state\=on\]\:text-accent-foreground[data-state=on] {
  --tw-text-opacity: 1;
  color: lch(var(--accent-foreground) / var(--tw-text-opacity, 1));
}
.data-\[state\=on\]\:text-primary-foreground[data-state=on] {
  --tw-text-opacity: 1;
  color: lch(var(--primary-foreground) / var(--tw-text-opacity, 1));
}
.data-\[state\=open\]\:text-muted-foreground[data-state=open] {
  --tw-text-opacity: 1;
  color: lch(var(--muted-foreground) / var(--tw-text-opacity, 1));
}
.data-\[disabled\=true\]\:opacity-50[data-disabled=true] {
  opacity: 0.5;
}
.data-\[disabled\]\:opacity-50[data-disabled] {
  opacity: 0.5;
}
.data-\[state\=open\]\:opacity-100[data-state=open] {
  opacity: 1;
}
.data-\[state\=active\]\:shadow-sm[data-state=active] {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.data-\[state\=closed\]\:duration-300[data-state=closed] {
  transition-duration: 300ms;
}
.data-\[state\=open\]\:duration-500[data-state=open] {
  transition-duration: 500ms;
}
.data-\[motion\^\=from-\]\:animate-in[data-motion^=from-] {
  animation-name: enter;
  animation-duration: 150ms;
  --tw-enter-opacity: initial;
  --tw-enter-scale: initial;
  --tw-enter-rotate: initial;
  --tw-enter-translate-x: initial;
  --tw-enter-translate-y: initial;
}
.data-\[open\=true\]\:animate-in[data-open=true] {
  animation-name: enter;
  animation-duration: 150ms;
  --tw-enter-opacity: initial;
  --tw-enter-scale: initial;
  --tw-enter-rotate: initial;
  --tw-enter-translate-x: initial;
  --tw-enter-translate-y: initial;
}
.data-\[state\=open\]\:animate-in[data-state=open] {
  animation-name: enter;
  animation-duration: 150ms;
  --tw-enter-opacity: initial;
  --tw-enter-scale: initial;
  --tw-enter-rotate: initial;
  --tw-enter-translate-x: initial;
  --tw-enter-translate-y: initial;
}
.data-\[state\=opened\]\:animate-in[data-state=opened] {
  animation-name: enter;
  animation-duration: 150ms;
  --tw-enter-opacity: initial;
  --tw-enter-scale: initial;
  --tw-enter-rotate: initial;
  --tw-enter-translate-x: initial;
  --tw-enter-translate-y: initial;
}
.data-\[state\=visible\]\:animate-in[data-state=visible] {
  animation-name: enter;
  animation-duration: 150ms;
  --tw-enter-opacity: initial;
  --tw-enter-scale: initial;
  --tw-enter-rotate: initial;
  --tw-enter-translate-x: initial;
  --tw-enter-translate-y: initial;
}
.data-\[motion\^\=to-\]\:animate-out[data-motion^=to-] {
  animation-name: exit;
  animation-duration: 150ms;
  --tw-exit-opacity: initial;
  --tw-exit-scale: initial;
  --tw-exit-rotate: initial;
  --tw-exit-translate-x: initial;
  --tw-exit-translate-y: initial;
}
.data-\[open\=false\]\:animate-out[data-open=false] {
  animation-name: exit;
  animation-duration: 150ms;
  --tw-exit-opacity: initial;
  --tw-exit-scale: initial;
  --tw-exit-rotate: initial;
  --tw-exit-translate-x: initial;
  --tw-exit-translate-y: initial;
}
.data-\[state\=closed\]\:animate-out[data-state=closed] {
  animation-name: exit;
  animation-duration: 150ms;
  --tw-exit-opacity: initial;
  --tw-exit-scale: initial;
  --tw-exit-rotate: initial;
  --tw-exit-translate-x: initial;
  --tw-exit-translate-y: initial;
}
.data-\[state\=hidden\]\:animate-out[data-state=hidden] {
  animation-name: exit;
  animation-duration: 150ms;
  --tw-exit-opacity: initial;
  --tw-exit-scale: initial;
  --tw-exit-rotate: initial;
  --tw-exit-translate-x: initial;
  --tw-exit-translate-y: initial;
}
.data-\[state\=inactive\]\:animate-out[data-state=inactive] {
  animation-name: exit;
  animation-duration: 150ms;
  --tw-exit-opacity: initial;
  --tw-exit-scale: initial;
  --tw-exit-rotate: initial;
  --tw-exit-translate-x: initial;
  --tw-exit-translate-y: initial;
}
.data-\[motion\^\=from-\]\:fade-in[data-motion^=from-] {
  --tw-enter-opacity: 0;
}
.data-\[motion\^\=to-\]\:fade-out[data-motion^=to-] {
  --tw-exit-opacity: 0;
}
.data-\[open\=false\]\:fade-out-0[data-open=false] {
  --tw-exit-opacity: 0;
}
.data-\[open\=true\]\:fade-in-0[data-open=true] {
  --tw-enter-opacity: 0;
}
.data-\[state\=active\]\:fade-in[data-state=active] {
  --tw-enter-opacity: 0;
}
.data-\[state\=closed\]\:fade-out-0[data-state=closed] {
  --tw-exit-opacity: 0;
}
.data-\[state\=hidden\]\:fade-out[data-state=hidden] {
  --tw-exit-opacity: 0;
}
.data-\[state\=hidden\]\:fade-out-0[data-state=hidden] {
  --tw-exit-opacity: 0;
}
.data-\[state\=open\]\:fade-in-0[data-state=open] {
  --tw-enter-opacity: 0;
}
.data-\[state\=visible\]\:fade-in[data-state=visible] {
  --tw-enter-opacity: 0;
}
.data-\[state\=visible\]\:fade-in-0[data-state=visible] {
  --tw-enter-opacity: 0;
}
.data-\[open\=false\]\:zoom-out-95[data-open=false] {
  --tw-exit-scale: .95;
}
.data-\[open\=true\]\:zoom-in-95[data-open=true] {
  --tw-enter-scale: .95;
}
.data-\[state\=closed\]\:zoom-out-95[data-state=closed] {
  --tw-exit-scale: .95;
}
.data-\[state\=open\]\:zoom-in-90[data-state=open] {
  --tw-enter-scale: .9;
}
.data-\[state\=open\]\:zoom-in-95[data-state=open] {
  --tw-enter-scale: .95;
}
.data-\[motion\=from-end\]\:slide-in-from-right-52[data-motion=from-end] {
  --tw-enter-translate-x: 13rem;
}
.data-\[motion\=from-start\]\:slide-in-from-left-52[data-motion=from-start] {
  --tw-enter-translate-x: -13rem;
}
.data-\[motion\=to-end\]\:slide-out-to-right-52[data-motion=to-end] {
  --tw-exit-translate-x: 13rem;
}
.data-\[motion\=to-start\]\:slide-out-to-left-52[data-motion=to-start] {
  --tw-exit-translate-x: -13rem;
}
.data-\[side\=bottom\]\:slide-in-from-top-2[data-side=bottom] {
  --tw-enter-translate-y: -0.5rem;
}
.data-\[side\=left\]\:slide-in-from-right-2[data-side=left] {
  --tw-enter-translate-x: 0.5rem;
}
.data-\[side\=right\]\:slide-in-from-left-2[data-side=right] {
  --tw-enter-translate-x: -0.5rem;
}
.data-\[side\=top\]\:slide-in-from-bottom-2[data-side=top] {
  --tw-enter-translate-y: 0.5rem;
}
.data-\[state\=closed\]\:slide-out-to-bottom[data-state=closed] {
  --tw-exit-translate-y: 100%;
}
.data-\[state\=closed\]\:slide-out-to-left[data-state=closed] {
  --tw-exit-translate-x: -100%;
}
.data-\[state\=closed\]\:slide-out-to-right[data-state=closed] {
  --tw-exit-translate-x: 100%;
}
.data-\[state\=closed\]\:slide-out-to-top[data-state=closed] {
  --tw-exit-translate-y: -100%;
}
.data-\[state\=open\]\:slide-in-from-bottom[data-state=open] {
  --tw-enter-translate-y: 100%;
}
.data-\[state\=open\]\:slide-in-from-left[data-state=open] {
  --tw-enter-translate-x: -100%;
}
.data-\[state\=open\]\:slide-in-from-right[data-state=open] {
  --tw-enter-translate-x: 100%;
}
.data-\[state\=open\]\:slide-in-from-top[data-state=open] {
  --tw-enter-translate-y: -100%;
}
.data-\[state\=closed\]\:duration-300[data-state=closed] {
  animation-duration: 300ms;
}
.data-\[state\=open\]\:duration-500[data-state=open] {
  animation-duration: 500ms;
}
.data-\[panel-group-direction\=vertical\]\:after\:left-0[data-panel-group-direction=vertical]::after {
  content: var(--tw-content);
  left: 0px;
}
.data-\[panel-group-direction\=vertical\]\:after\:h-1[data-panel-group-direction=vertical]::after {
  content: var(--tw-content);
  height: 0.25rem;
}
.data-\[panel-group-direction\=vertical\]\:after\:w-full[data-panel-group-direction=vertical]::after {
  content: var(--tw-content);
  width: 100%;
}
.data-\[panel-group-direction\=vertical\]\:after\:-translate-y-1\/2[data-panel-group-direction=vertical]::after {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[panel-group-direction\=vertical\]\:after\:translate-x-0[data-panel-group-direction=vertical]::after {
  content: var(--tw-content);
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[state\=open\]\:hover\:bg-sidebar-accent:hover[data-state=open] {
  background-color: hsl(var(--sidebar-accent));
}
.data-\[state\=open\]\:hover\:text-sidebar-accent-foreground:hover[data-state=open] {
  color: hsl(var(--sidebar-accent-foreground));
}
.group[data-collapsible=offcanvas] .group-data-\[collapsible\=offcanvas\]\:left-\[calc\(var\(--sidebar-width\)\*-1\)\] {
  left: calc(var(--sidebar-width) * -1);
}
.group[data-collapsible=offcanvas] .group-data-\[collapsible\=offcanvas\]\:right-\[calc\(var\(--sidebar-width\)\*-1\)\] {
  right: calc(var(--sidebar-width) * -1);
}
.group[data-side=left] .group-data-\[side\=left\]\:-right-4 {
  right: -1rem;
}
.group[data-side=right] .group-data-\[side\=right\]\:left-0 {
  left: 0px;
}
.group[data-collapsible=icon] .group-data-\[collapsible\=icon\]\:-mt-8 {
  margin-top: -2rem;
}
.group[data-collapsible=icon] .group-data-\[collapsible\=icon\]\:hidden {
  display: none;
}
.group[data-collapsible=icon] .group-data-\[collapsible\=icon\]\:\!size-8 {
  width: 2rem !important;
  height: 2rem !important;
}
.group[data-collapsible=icon] .group-data-\[collapsible\=icon\]\:w-\[--sidebar-width-icon\] {
  width: var(--sidebar-width-icon);
}
.group[data-collapsible=icon] .group-data-\[collapsible\=icon\]\:w-\[calc\(var\(--sidebar-width-icon\)_\+_theme\(spacing\.4\)\)\] {
  width: calc(var(--sidebar-width-icon) + 1rem);
}
.group[data-collapsible=icon] .group-data-\[collapsible\=icon\]\:w-\[calc\(var\(--sidebar-width-icon\)_\+_theme\(spacing\.4\)_\+2px\)\] {
  width: calc(var(--sidebar-width-icon) + 1rem + 2px);
}
.group[data-collapsible=offcanvas] .group-data-\[collapsible\=offcanvas\]\:w-0 {
  width: 0px;
}
.group[data-collapsible=offcanvas] .group-data-\[collapsible\=offcanvas\]\:translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group[data-side=right] .group-data-\[side\=right\]\:rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group[data-state=open] .group-data-\[state\=open\]\:rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group[data-collapsible=icon] .group-data-\[collapsible\=icon\]\:overflow-hidden {
  overflow: hidden;
}
.group[data-collapsible=icon] .group-data-\[collapsible\=icon\]\:\!rounded-full {
  border-radius: 9999px !important;
}
.group[data-variant=floating] .group-data-\[variant\=floating\]\:rounded-lg {
  border-radius: var(--radius);
}
.group[data-variant=floating] .group-data-\[variant\=floating\]\:border {
  border-width: 1px;
}
.group[data-side=left] .group-data-\[side\=left\]\:border-r {
  border-right-width: 1px;
}
.group[data-side=right] .group-data-\[side\=right\]\:border-l {
  border-left-width: 1px;
}
.group[data-collapsible=icon] .group-data-\[collapsible\=icon\]\:\!border-none {
  border-style: none !important;
}
.group[data-variant=floating] .group-data-\[variant\=floating\]\:border-sidebar-border {
  border-color: hsl(var(--sidebar-border));
}
.group[data-collapsible=icon] .group-data-\[collapsible\=icon\]\:\!p-0 {
  padding: 0px !important;
}
.group[data-collapsible=icon] .group-data-\[collapsible\=icon\]\:\!p-2 {
  padding: 0.5rem !important;
}
.group[data-collapsible=icon] .group-data-\[collapsible\=icon\]\:opacity-0 {
  opacity: 0;
}
.group[data-variant=floating] .group-data-\[variant\=floating\]\:shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.group[data-collapsible=offcanvas] .group-data-\[collapsible\=offcanvas\]\:after\:left-full::after {
  content: var(--tw-content);
  left: 100%;
}
.group[data-collapsible=offcanvas] .group-data-\[collapsible\=offcanvas\]\:hover\:bg-sidebar:hover {
  background-color: hsl(var(--sidebar-background));
}
.peer\/menu-button[data-size=default] ~ .peer-data-\[size\=default\]\/menu-button\:top-1\.5 {
  top: 0.375rem;
}
.peer\/menu-button[data-size=lg] ~ .peer-data-\[size\=lg\]\/menu-button\:top-2\.5 {
  top: 0.625rem;
}
.peer\/menu-button[data-size=sm] ~ .peer-data-\[size\=sm\]\/menu-button\:top-1 {
  top: 0.25rem;
}
.peer[data-variant=inset] ~ .peer-data-\[variant\=inset\]\:min-h-\[calc\(100svh-theme\(spacing\.4\)\)\] {
  min-height: calc(100svh - 1rem);
}
.peer\/menu-button[data-active=true] ~ .peer-data-\[active\=true\]\/menu-button\:text-sidebar-accent-foreground {
  color: hsl(var(--sidebar-accent-foreground));
}
@media (min-width: 640px) {
  .sm\:flex {
    display: flex;
  }
  .sm\:max-h-\[calc\(100\%-75px\)\] {
    max-height: calc(100% - 75px);
  }
  .sm\:max-w-md {
    max-width: 28rem;
  }
  .sm\:flex-row {
    flex-direction: row;
  }
  .sm\:justify-end {
    justify-content: flex-end;
  }
  .sm\:gap-0 {
    gap: 0px;
  }
  .sm\:gap-2\.5 {
    gap: 0.625rem;
  }
  .sm\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .sm\:rounded-lg {
    border-radius: var(--radius);
  }
  .sm\:text-left {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .md\:absolute {
    position: absolute;
  }
  .md\:w-\[var\(--radix-navigation-menu-viewport-width\)\] {
    width: var(--radix-navigation-menu-viewport-width);
  }
  .md\:w-auto {
    width: auto;
  }
  .md\:w-full {
    width: 100%;
  }
  .md\:opacity-0 {
    opacity: 0;
  }
  .after\:md\:hidden::after {
    content: var(--tw-content);
    display: none;
  }
  .peer[data-variant=inset] ~ .md\:peer-data-\[variant\=inset\]\:m-2 {
    margin: 0.5rem;
  }
  .peer[data-state=collapsed][data-variant=inset] ~ .md\:peer-data-\[state\=collapsed\]\:peer-data-\[variant\=inset\]\:ml-2 {
    margin-left: 0.5rem;
  }
  .peer[data-variant=inset] ~ .md\:peer-data-\[variant\=inset\]\:ml-0 {
    margin-left: 0px;
  }
  .peer[data-variant=inset] ~ .md\:peer-data-\[variant\=inset\]\:rounded-xl {
    border-radius: 0.75rem;
  }
  .peer[data-variant=inset] ~ .md\:peer-data-\[variant\=inset\]\:shadow {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow:
      var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000),
      var(--tw-shadow);
  }
}
@media (min-width: 1024px) {
  .lg\:flex {
    display: flex;
  }
  .lg\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }
}
.\[\&\:has\(\>\.day-range-end\)\]\:rounded-r-xl:has(> .day-range-end) {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.\[\&\:has\(\>\.day-range-start\)\]\:rounded-l-xl:has(> .day-range-start) {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.\[\&\:has\(\[aria-selected\]\)\]\:rounded-xl:has([aria-selected]) {
  border-radius: 0.75rem;
}
.\[\&\:has\(\[aria-selected\]\)\]\:bg-accent:has([aria-selected]) {
  --tw-bg-opacity: 1;
  background-color: lch(var(--accent) / var(--tw-bg-opacity, 1));
}
.first\:\[\&\:has\(\[aria-selected\]\)\]\:rounded-l-xl:has([aria-selected]):first-child {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.last\:\[\&\:has\(\[aria-selected\]\)\]\:rounded-r-xl:has([aria-selected]):last-child {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.\[\&\:has\(\[aria-selected\]\.day-outside\)\]\:bg-accent\/50:has([aria-selected].day-outside) {
  background-color: lch(var(--accent) / 0.5);
}
.\[\&\:has\(\[aria-selected\]\.day-range-end\)\]\:rounded-r-xl:has([aria-selected].day-range-end) {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.\[\&\:has\(\[role\=checkbox\]\)\]\:pr-0:has([role=checkbox]) {
  padding-right: 0px;
}
.\[\&\>button\]\:hidden > button {
  display: none;
}
.\[\&\>p\]\:text-blue-700 > p {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity, 1));
}
.\[\&\>p\]\:text-gray-700 > p {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}
.\[\&\>p\]\:text-green-700 > p {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity, 1));
}
.\[\&\>p\]\:text-red-700 > p {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity, 1));
}
.\[\&\>p\]\:text-yellow-700 > p {
  --tw-text-opacity: 1;
  color: rgb(161 98 7 / var(--tw-text-opacity, 1));
}
.\[\&\>span\:last-child\]\:truncate > span:last-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.\[\&\>span\]\:text-blue-500 > span {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity, 1));
}
.\[\&\>span\]\:text-gray-500 > span {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}
.\[\&\>span\]\:text-green-500 > span {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity, 1));
}
.\[\&\>span\]\:text-red-500 > span {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity, 1));
}
.\[\&\>span\]\:text-yellow-500 > span {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity, 1));
}
.\[\&\>svg\+div\]\:translate-y-\[-3px\] > svg + div {
  --tw-translate-y: -3px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.\[\&\>svg\]\:absolute > svg {
  position: absolute;
}
.\[\&\>svg\]\:left-4 > svg {
  left: 1rem;
}
.\[\&\>svg\]\:top-3 > svg {
  top: 0.75rem;
}
.\[\&\>svg\]\:size-3\.5 > svg {
  width: 0.875rem;
  height: 0.875rem;
}
.\[\&\>svg\]\:size-4 > svg {
  width: 1rem;
  height: 1rem;
}
.\[\&\>svg\]\:h-2\.5 > svg {
  height: 0.625rem;
}
.\[\&\>svg\]\:h-3 > svg {
  height: 0.75rem;
}
.\[\&\>svg\]\:w-2\.5 > svg {
  width: 0.625rem;
}
.\[\&\>svg\]\:w-3 > svg {
  width: 0.75rem;
}
.\[\&\>svg\]\:shrink-0 > svg {
  flex-shrink: 0;
}
.\[\&\>svg\]\:text-amber-900 > svg {
  --tw-text-opacity: 1;
  color: rgb(120 53 15 / var(--tw-text-opacity, 1));
}
.\[\&\>svg\]\:text-blue-900 > svg {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity, 1));
}
.\[\&\>svg\]\:text-destructive > svg {
  --tw-text-opacity: 1;
  color: lch(var(--destructive) / var(--tw-text-opacity, 1));
}
.\[\&\>svg\]\:text-foreground > svg {
  --tw-text-opacity: 1;
  color: lch(var(--foreground) / var(--tw-text-opacity, 1));
}
.\[\&\>svg\]\:text-green-900 > svg {
  --tw-text-opacity: 1;
  color: rgb(20 83 45 / var(--tw-text-opacity, 1));
}
.\[\&\>svg\]\:text-muted-foreground > svg {
  --tw-text-opacity: 1;
  color: lch(var(--muted-foreground) / var(--tw-text-opacity, 1));
}
.\[\&\>svg\]\:text-purple-900 > svg {
  --tw-text-opacity: 1;
  color: rgb(88 28 135 / var(--tw-text-opacity, 1));
}
.\[\&\>svg\]\:text-sidebar-accent-foreground > svg {
  color: hsl(var(--sidebar-accent-foreground));
}
.\[\&\>svg\~\*\]\:pl-7 > svg ~ * {
  padding-left: 1.75rem;
}
.\[\&\>tr\]\:last\:border-b-0:last-child > tr {
  border-bottom-width: 0px;
}
.\[\&\[data-panel-group-direction\=vertical\]\>div\]\:rotate-90[data-panel-group-direction=vertical] > div {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.\[\&\[data-state\=open\]\>svg\]\:rotate-180[data-state=open] > svg {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.\[\&_\.recharts-cartesian-axis-tick_text\]\:fill-muted-foreground .recharts-cartesian-axis-tick text {
  fill: lch(var(--muted-foreground) / 1);
}
.\[\&_\.recharts-cartesian-grid_line\[stroke\=\'\#ccc\'\]\]\:stroke-border\/50 .recharts-cartesian-grid line[stroke="#ccc"] {
  stroke: lch(var(--border) / 0.5);
}
.\[\&_\.recharts-curve\.recharts-tooltip-cursor\]\:stroke-border .recharts-curve.recharts-tooltip-cursor {
  stroke: lch(var(--border) / 1);
}
.\[\&_\.recharts-dot\[stroke\=\'\#fff\'\]\]\:stroke-transparent .recharts-dot[stroke="#fff"] {
  stroke: transparent;
}
.\[\&_\.recharts-layer\]\:outline-none .recharts-layer {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.\[\&_\.recharts-polar-grid_\[stroke\=\'\#ccc\'\]\]\:stroke-border .recharts-polar-grid [stroke="#ccc"] {
  stroke: lch(var(--border) / 1);
}
.\[\&_\.recharts-radial-bar-background-sector\]\:fill-muted .recharts-radial-bar-background-sector {
  fill: lch(var(--muted) / 1);
}
.\[\&_\.recharts-rectangle\.recharts-tooltip-cursor\]\:fill-muted .recharts-rectangle.recharts-tooltip-cursor {
  fill: lch(var(--muted) / 1);
}
.\[\&_\.recharts-reference-line_\[stroke\=\'\#ccc\'\]\]\:stroke-border .recharts-reference-line [stroke="#ccc"] {
  stroke: lch(var(--border) / 1);
}
.\[\&_\.recharts-sector\[stroke\=\'\#fff\'\]\]\:stroke-transparent .recharts-sector[stroke="#fff"] {
  stroke: transparent;
}
.\[\&_\.recharts-sector\]\:outline-none .recharts-sector {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.\[\&_\.recharts-surface\]\:outline-none .recharts-surface {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.\[\&_\[cmdk-group-heading\]\]\:px-2 [cmdk-group-heading] {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.\[\&_\[cmdk-group-heading\]\]\:py-1\.5 [cmdk-group-heading] {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.\[\&_\[cmdk-group-heading\]\]\:text-xs [cmdk-group-heading] {
  font-size: 0.75rem;
  line-height: 1rem;
}
.\[\&_\[cmdk-group-heading\]\]\:font-medium [cmdk-group-heading] {
  font-weight: 500;
}
.\[\&_\[cmdk-group-heading\]\]\:uppercase [cmdk-group-heading] {
  text-transform: uppercase;
}
.\[\&_\[cmdk-group-heading\]\]\:text-muted-foreground [cmdk-group-heading] {
  --tw-text-opacity: 1;
  color: lch(var(--muted-foreground) / var(--tw-text-opacity, 1));
}
.\[\&_\[cmdk-group-heading\]\]\:text-primary [cmdk-group-heading] {
  --tw-text-opacity: 1;
  color: lch(var(--primary) / var(--tw-text-opacity, 1));
}
.\[\&_\[cmdk-group\]\:not\(\[hidden\]\)_\~\[cmdk-group\]\]\:pt-0 [cmdk-group]:not([hidden]) ~ [cmdk-group] {
  padding-top: 0px;
}
.\[\&_\[cmdk-group\]\]\:px-2 [cmdk-group] {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.\[\&_\[cmdk-input-wrapper\]_svg\]\:h-5 [cmdk-input-wrapper] svg {
  height: 1.25rem;
}
.\[\&_\[cmdk-input-wrapper\]_svg\]\:w-5 [cmdk-input-wrapper] svg {
  width: 1.25rem;
}
.\[\&_\[cmdk-input\]\]\:h-12 [cmdk-input] {
  height: 3rem;
}
.\[\&_\[cmdk-item\]\]\:px-2 [cmdk-item] {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.\[\&_\[cmdk-item\]\]\:py-3 [cmdk-item] {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.\[\&_\[cmdk-item\]_svg\]\:h-5 [cmdk-item] svg {
  height: 1.25rem;
}
.\[\&_\[cmdk-item\]_svg\]\:w-5 [cmdk-item] svg {
  width: 1.25rem;
}
.\[\&_kbd\]\:border-purple-600 kbd {
  --tw-border-opacity: 1;
  border-color: rgb(147 51 234 / var(--tw-border-opacity, 1));
}
.\[\&_kbd\]\:text-purple-600 kbd {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity, 1));
}
.\[\&_p\]\:leading-relaxed p {
  line-height: 1.625;
}
.\[\&_svg\]\:size-4 svg {
  width: 1rem;
  height: 1rem;
}
.\[\&_tr\:last-child\]\:border-0 tr:last-child {
  border-width: 0px;
}
.\[\&_tr\]\:border-b tr {
  border-bottom-width: 1px;
}
.\[\&_tr\]\:bg-secondary tr {
  --tw-bg-opacity: 1;
  background-color: lch(var(--secondary) / var(--tw-bg-opacity, 1));
}
[data-side=left][data-collapsible=offcanvas] .\[\[data-side\=left\]\[data-collapsible\=offcanvas\]_\&\]\:-right-2 {
  right: -0.5rem;
}
[data-side=left][data-state=collapsed] .\[\[data-side\=left\]\[data-state\=collapsed\]_\&\]\:cursor-e-resize {
  cursor: e-resize;
}
[data-side=left] .\[\[data-side\=left\]_\&\]\:cursor-w-resize {
  cursor: w-resize;
}
[data-side=right][data-collapsible=offcanvas] .\[\[data-side\=right\]\[data-collapsible\=offcanvas\]_\&\]\:-left-2 {
  left: -0.5rem;
}
[data-side=right][data-state=collapsed] .\[\[data-side\=right\]\[data-state\=collapsed\]_\&\]\:cursor-w-resize {
  cursor: w-resize;
}
[data-side=right] .\[\[data-side\=right\]_\&\]\:cursor-e-resize {
  cursor: e-resize;
}
